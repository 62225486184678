module controllers {
    export module forex {
        interface IForexUpdateScope extends ng.IScope {
        }

        interface IForexUpdateParams extends ng.ui.IStateParamsService {
            fecId: number;
        }

        export class forexUpdateCtrl {

            static $inject = [
                 "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'classificationValueService',
                'documentRepositoryService',
                'lookupService',
                "supplierService",
                "localeService",
                'forexService',
                "customerService",
                "paymentMethodService",
                "counterService",
                'currencyService',
                'bankService',
                'userAccountService',
                "documentTypeService",
            ];

            fecId: number;
            breadCrumbDesc: string  = "";
            forexHook: any;
            forex : interfaces.forex.IForex;
            selectedTab: number = 0;

            maturityStartDateOpen: boolean = false;
            maturityEndDateOpen: boolean = false;
            showAll: boolean = false;

            public forexStatus: interfaces.master.IStatusData;

            documentRepository: interfaces.documentManagement.IDocumentRepository;
            documentTypes: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            apiAllocationList: uiGrid.IGridApi;

            apiSurrenderList: uiGrid.IGridApi;
            selectedSurrender: uiGrid.IGridRow;
            selectedSurrenderEntity: interfaces.forex.IForexSurrender;
            selectedSurrenderRowIndex: number = 0;

            forexContractTypeModel: interfaces.applicationcore.IDropdownModel;
            maturityTypeModelList:  interfaces.applicationcore.IDropdownModel[] = [];

            selectedAllocation : uiGrid.IGridRow;
            selectedAllocationEntity: interfaces.forex.IForexAllocation;
            selectedRowIndex: number = 0;

            IsDisabled: boolean = false;

            constructor(
                private $scope: IForexUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: IForexUpdateParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public lookupService: interfaces.applicationcore.ILookupService,
                private supplierService: interfaces.master.ISupplierService,
                private localeService: interfaces.applicationcore.ILocaleService,
                private forexService: interfaces.forex.IForexService,
                private customerService: interfaces.master.ICustomerService,
                public paymentMethodService: services.master.paymentMethodService,
                private counterService: interfaces.master.ICounterService,
                private currencyService: interfaces.applicationcore.ICurrencyService,
                public bankService: interfaces.master.IBankService,
                private userAccountService: interfaces.applicationcore.IUserAccountService,
                private documentTypeService: interfaces.documentManagement.IDocumentTypeService,
            ) {
                this.fecId = $stateParams.fecId;

                this.forexHook = $transitions.onSuccess({
                    to: 'auth.Forex.Update',
                    from: 'auth.Forex.Update.**'
                }, () => {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'forex.update'
                    }, () => {
                        return this.loadForex(this.fecId);
                    });
                });

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadForex(this.fecId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'forex.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
            }

            loadControls() {
                var promises = [];
     
                promises.push(this.getDocuments(this.showAll));
                promises.push(this.loadStatus());
                promises.push(this.loadMaturityTypes());
        
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pay.update'
                }, () => {
                    return this.$q.all(promises);
                });
            }


            recalculateSurrenderBalances(){
                this.forex.AmountSurrendered = this.forex.forexSurrenderList
                .reduce((sum, current) => sum + current.Amount, 0);
                this.forex.AmountSurrenderedRemaining = this.forex.ContractAmount - this.forex.AmountSurrendered;
            }

            loadForex(Id: number) { 

                return this.forexService.getForex().query({ Id: Id }, (data: interfaces.forex.IForex) => {

                    this.forex = data;
                    this.breadCrumbDesc = this.forex.Code;

                    //this.isLoadingDetail = true;
                    this.$timeout(() => 
                    {
                        this.loadControls();
                        this.gvwAllocations.data = this.forex.forexAllocationList;
                        this.gvwSurrenders.data = this.forex.forexSurrenderList;


                        this.recalculateSurrenderBalances();

                        if (this.forex.typeId === 466)
                        {
                            this.gvwAllocations.columnDefs[4].visible = true;
                            this.gvwAllocations.columnDefs[5].visible = false;
                        }
                        else  if (this.forex.typeId === 467)
                        {
                            this.gvwAllocations.columnDefs[4].visible = false;
                            this.gvwAllocations.columnDefs[5].visible = true;
                        }
                        else
                        {
                            this.gvwAllocations.columnDefs[3].visible = false;
                            this.gvwAllocations.columnDefs[4].visible = false;
                            this.gvwAllocations.columnDefs[5].visible = false;
                        }

                    /*    this.gvwInvoices.data = this.payment.InvoiceList;
                        this.gvwLC.data = this.payment.LetterOfCreditList;
                        this.loadControls();
                        this.isLoadingDetail = false;*/
                    }
                    );
                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            loadBanks() {
                return this.bankService.getDropdownList(this.forex.OwnerEntityId, "").query({
                }, (resultList) => {
                }).$promise; 
            }

            loadContractTypes(){
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ForexContractType
                }, (resultList) => {
                       
                }).$promise;
            }

            
            loadMaturityTypes(){
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                    classificationId: Enum.ClassificationEnum.ForexContactMaturity
                }, (resultList) => {
                        this.maturityTypeModelList = resultList;
                }).$promise;
            }

            loadStatus() {
                this.forexStatus = {
                    StatusName: this.forex.StatusName,
                    Id: this.forex.Id,
                    EntityId: this.forex.OwnerEntityId,
                    CurrentStatusId: this.forex.StatusId,
                    CurrentStatusComments: this.forex.StatusLastComments,
                    CurrentStatusStampDate: this.forex.StatusLastUpdateStampDate,
                    CurrentStatusStampFullName: this.forex.StatusLastUpdateStampFullName,
                    CurrentStatusType:  Enum.EnumStatusType.ForexContract ,
                    EnumStatusType: Enum.EnumStatusType.ForexContract ,
                    EnumStatusAuthorisationType:  Enum.EnumStatusAuthorisationType.ForexContract,
                    IsEditable: true
                };
            }


            contractTypeChanged(forexContractTypeModel: interfaces.applicationcore.IDropdownModel){
                if (forexContractTypeModel){
                    this.forexContractTypeModel = forexContractTypeModel;

                    if (this.forexContractTypeModel.Id == 415)
                    {
                        //Maturity Type = Date = 418
                        var maturity = this.maturityTypeModelList.filter(x => x.Id == 418)[0];
                        this.forex.MaturityType = maturity;
                    }

                    if (this.forexContractTypeModel.Id == 416)
                    {
                        //Maturity Type = Any Date Between = 419
                        var maturity = this.maturityTypeModelList.filter(x => x.Id == 419)[0];
                        this.forex.MaturityType= maturity;
                    }
                    
                    if (this.forexContractTypeModel.Id == 417)
                    {
                        //Maturity Type = At Any Date Up To = 420
                        var maturity = this.maturityTypeModelList.filter(x => x.Id == 420)[0];
                        this.forex.MaturityType = maturity;
                    }              

                }
            }

            loadUsers(searchText: string) {
                if (this.forex) {
                    return this.userAccountService.getDropdownList(searchText, this.forex.OwnerEntityId).query(
                        (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            getDocuments(showAll:boolean) {
                return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.forex.Code, systemType: Enum.EnumSystemType.ForexContract, ownerEntityId: this.forex.OwnerEntityId,showall:showAll}, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    this.documentRepository = documentRepository;

                    if (this.documentRepository)
                        this.loadDocumentTypes();

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }

            printDocuments() {
                this.$state.go("auth.Forex.Update.Documents");
            }


            loadDocumentTypes() {

                return this.documentTypeService.getDocumentTypes().query({
                    repTypeId: this.documentRepository.RepositoryTypeId,
                    ownerEntityId: this.documentRepository.OwnerEntityId,
                    referenceNumber: this.forex.Code
                }, (documentTypes: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    this.documentTypes = documentTypes;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }


            saveForex() {
                return this.forexService.save().save(this.forex, (data: interfaces.applicationcore.IMessageHandler) => {
                     this.generalService.displayMessageHandler(data);
                  
                 }, (errorResponse) => {
                     this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
 
                 }).$promise;
             };

             Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'forex.update'
                }, () => {
                    return this.saveForex().then((result) => {
                        this.generalService.displayMessageHandler(result);

                        
                        /*if (this.apiAllocationList) {
                            this.apiAllocationList.rowEdit.setRowsClean(this.forex.forexAllocationList);
                        }

                        if (this.apiSurrenderList) {
                            this.apiSurrenderList.rowEdit.setRowsClean(this.forex.forexSurrenderList);
                        }*/

                        if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'pay.update'
                                }, () => {
                                    return this.loadForex(this.fecId);
                                });
                            });
                        }
                    });
                });
            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'forex.update'
                }, () => {
                    return this.saveForex().then(() => {
                        this.Close();
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        this.$anchorScroll("topAnchor");
                    });
                });
            }

            Close() {
                this.$state.go("^");
            }

            
            updateStatus = (newStatusId: number, comments: string) => {
                var paramsObject: interfaces.master.IStatusChange = {
                    referenceId: this.forex.Id,
                    newStatusId: newStatusId,
                    comments: comments
                };
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'pay.update'
                },
                    () => {
                        return this.saveForex().then(() => {
                            this.forexService.changeStatus().save({}, paramsObject, (data: interfaces.applicationcore.IMessageHandler) => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'pay.update'
                                }, () => {
                                        this.selectedTab = 5;
                                        return this.loadForex(this.fecId);
                                    });
    
                                this.generalService.displayMessageHandler(data);
                            }, (failureData) => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'pay.update'
                                }, () => {
                                        this.selectedTab = 5;
                                        return this.loadForex(this.fecId);
                                    });
                                    
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            });
                        }, () => {
                            return;
                        });
                    });
            };


            registerSurrenderGridApi(gridApi: uiGrid.IGridApi) {
                
                this.apiSurrenderList = gridApi;
                this.apiSurrenderList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.selectedSurrender = selected;
                });

                this.$timeout(() => {
                    if (this.selectedSurrender)
                        this.apiSurrenderList.selection.selectRow(this.selectedSurrender.entity);
                });
            }


            gvwSurrenders: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: true,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerSurrenderGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-click="grid.appScope.forexUpdateCtrl.editForexSurrender(row.entity)"  class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: true
                },
                {
                    name: 'DateDrawn',
                    displayName: 'Date Drawn',
                    field: 'DateDrawn',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD |  momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 100
                },
                {
                    name: 'ValueDate',
                    displayName: 'Value Date',
                    field: 'ValueDate',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD |  momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 100
                },                 
                {
                    name: "Amount",
                    displayName: "Amount",
                    field: "Amount",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }
                ,{
                    name: 'Type',
                    displayName: 'Type',
                    field: 'Type',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD .Display}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 180
                },
                {
                    name: 'Reference1',
                    displayName: 'Reference 1',
                    field: 'Reference1',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 100
                },
                {
                    name: 'Reference2',
                    displayName: 'Reference 2',
                    field: 'Reference2',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 100
                }]
            };



            registerGridApi(gridApi: uiGrid.IGridApi) {
                
                this.apiAllocationList = gridApi;
                this.apiAllocationList.selection.on.rowSelectionChanged(this.$scope, (selected) => {
                    this.selectedAllocation = selected;
                });

                this.$timeout(() => {
                    if (this.selectedAllocation)
                        this.apiAllocationList.selection.selectRow(this.selectedAllocation.entity);
                });
            }

            gvwAllocations: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: true,
                enableColumnResizing: true,
                enableRowSelection: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: true,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                },
                {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                        <button type="button" ng-click="grid.appScope.forexUpdateCtrl.editForexAllocation(row.entity)"  class="btn btn-default btn-sm">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35,
                    visible: true
                },
                {
                    name: 'Entity',
                    displayName: 'Entity',
                    field: 'Entity',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Display}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 100
                },
                {
                    name: 'Company',
                    displayName: 'Company',
                    field: 'Company',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Display}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 300
                },                 
                {
                    name: 'PurchaseOrder',
                    displayName: 'Purchase Order',
                    field: 'PurchaseOrder',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Display}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 140,
                    visible: true
                },            
                {
                    name: 'SalesOrder',
                    displayName: 'Sales Order',
                    field: 'SalesOrder',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Display}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 140,
                    visible:  true
                },
                {
                    name: "Amount",
                    displayName: "Amount",
                    field: "Amount",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }
                ,
                {
                    name: "Balance",
                    displayName: "Balance",
                    field: "Balance",
                    width: 180,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, {
                    name: 'Currency',
                    displayName: 'Currency',
                    field: 'Currency',
                    enableCellEditOnFocus: false,
                    cellTemplate: `<div class=\"ui-grid-cell-contents\">{{MODEL_COL_FIELD.Code}}</div>`,
                    enableSorting: false,
                    enableCellEdit: false,
                    width: 100
                },]
            };

            editForexAllocation(forexAllocation: interfaces.forex.IForexAllocation)
            {
                
                this.selectedAllocationEntity = forexAllocation;

                this.AddAllocation();
            }

            editForexSurrender(forexSurrender: interfaces.forex.IForexSurrender)
            {
                
                this.selectedSurrenderEntity = forexSurrender;

                this.AddSurrender();
            }

            recalculateBalances(){
                this.forex.AmountAllocated = this.forex.forexAllocationList
                .reduce((sum, current) => sum + current.Amount, 0);
                this.forex.AmountUnallocated = this.forex.ContractAmount - this.forex.AmountAllocated;
            }

            RemoveSurrender(){
                if (this.apiSurrenderList && this.apiSurrenderList.selection.getSelectedRows().length > 0) {

                    this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to remove the surrender?").then((result) => {
                        var selectedRow: interfaces.forex.IForexSurrender = this.apiSurrenderList.selection.getSelectedRows()[0];

                        if (result && (!selectedRow.Id || selectedRow.Id <= 0)) {
                            _.remove(this.forex.forexSurrenderList, selectedRow);
                            _.remove(this.gvwSurrenders.data, (o) => { return selectedRow === o;});

                        } else if(result) {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'forex.update'
                            },
                                () => {
                                    return this.forexService.removeSurrender(selectedRow.Id).remove((result) => {
                                        this.generalService.displayMessageHandler(result);
                                        if (!result.HasErrorMessage){
                                            this.loadForex(this.$stateParams.fecId);
                                            this.selectedSurrender = null;
                                            this.selectedSurrenderEntity = null;
                                            this.apiSurrenderList.selection.clearSelectedRows();
                        
                                        }
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    }).$promise;
                                });
                        }


                    });

                }
                else
                {
                    this.generalService.displayMessage("Please select a Surrender to remove", Enum.EnumMessageType.Warning);
                }
            }


            RemoveAllocation(){
                if (this.apiAllocationList && this.apiAllocationList.selection.getSelectedRows().length > 0) {

                    this.generalService.displayConfirmationBox("Confirm Remove", "Are you sure you want to remove the allocation?").then((result) => {
                        var selectedRow: interfaces.forex.IForexAllocation = this.apiAllocationList.selection.getSelectedRows()[0];

                        if (result && (!selectedRow.Id || selectedRow.Id <= 0)) {
                            _.remove(this.forex.forexAllocationList, selectedRow);
                            _.remove(this.gvwAllocations.data, (o) => { return selectedRow === o;});

                        } else if(result) {
                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'forex.update'
                            },
                                () => {
                                    return this.forexService.removeAllocation(selectedRow.Id).remove((result) => {
                                        this.generalService.displayMessageHandler(result);
                                        if (!result.HasErrorMessage){
                                            this.loadForex(this.$stateParams.fecId);
                                            this.selectedAllocation = null;
                                            this.selectedAllocationEntity = null;
                                            this.apiAllocationList.selection.clearSelectedRows();
                        
                                        }
                                    }, (errorResponse) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                    }).$promise;
                                });
                        }


                    });

                }
                else
                {
                    this.generalService.displayMessage("Please select an allocation to remove", Enum.EnumMessageType.Warning);
                }
            }

            AddSurrender(){
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `<div class="modal-header"><h3 class="modal-title" id="modal-title">Forex Contract Surrender</h3></div>
                                <div class="modal-body"> 
                                <div class="gts-padding">
                                <fieldset>
                                    <div class="row vertical-align" ng-if="!addNewCtrl.disabled">
                                        <div class="col-md-3">
                                            <req></req>
                                            <label>Allocation</label>
                                        </div>
                                        <div class="col-md-5">
                                            <gts-dropdown name="allocation" ng-model="addNewCtrl.Allocation"   load-data="addNewCtrl.loadAllocations()"  list="AllocationList" required="true"  pagination-search="false" refresh-delay="1000"></gts-dropdown>
                                        </div>
                                    </div>
                                    <div class="row vertical-align">
                                        <div class="col-md-3">
                                            <req></req>
                                            <label>Date Drawn</label>
                                        </div>
                                        <div class="col-md-5">
                                           {{addNewCtrl.DateDrawn |  momentDateFilter: 'YYYY/MM/DD'}}
                                        </div>
                                    </div>   

                                    <div class="row vertical-align">
                                        <div class="col-md-3">
                                            <opt></opt>
                                            <label>Value Date</label>
                                        </div>
                                        <div class="col-md-5">
                                            <p class="input-group input-group-sm">
                                                <gts-date format="yyyy/MM/dd"  ng-disabled = "addNewCtrl.disabled"
                                                    ng-model="addNewCtrl.ValueDate"
                                                name="ValueDate">
                                                </gts-date>
                                            </p>
                                        </div>
                                    </div>  

                                     <div class="row vertical-align"  >
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Amount</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                        <p class="input-group input-group-sm has-feedback"
                                                            ng-class="{'has-error': addNewCtrl.Amount.$invalid}">
                                                            <input type="number" ignore-wheel name="Amount" ng-disabled = "addNewCtrl.disabled"
                                                                ng-model="addNewCtrl.Amount" class="form-control" step="0.01" />
                                                            <span class="input-group-addon">{{addNewCtrl.currencyModel.Code}}</span>
                                                        </p>                                                            
                                                        </div>                                                      
                                    </div> 
                                    
                                    <div class="row vertical-align">
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Type</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <gts-dropdown ng-disabled = "addNewCtrl.disabled"  name="type" ng-model="addNewCtrl.Type"   load-data="addNewCtrl.loadSurrenderTypes()" required="true"  pagination-search="false" refresh-delay="1000"></gts-dropdown>
                                                        </div>
                                    </div>
                                    
                                    <div class="row vertical-align">
                                        <div class="col-md-3">
                                            <req></req>
                                            <label>Reference 1</label>
                                        </div>
                                        <div class="col-md-5">
                                            <p class="input-group-sm">
                                                <input class="form-control" type="text"
                                                    ng-model="addNewCtrl.Reference1" name="Reference1" ng-disabled = "addNewCtrl.disabled"
                                                    aria-label="Reference1">
                                            </p>
                                        </div>
                                    </div>     
                                    
                                    <div class="row vertical-align">
                                        <div class="col-md-3">
                                            <opt></opt>
                                            <label>Reference 2</label>
                                        </div>
                                        <div class="col-md-5">
                                            <p class="input-group-sm">
                                                <input class="form-control" type="text"
                                                    ng-model="addNewCtrl.Reference2" name="Reference2" ng-disabled = "addNewCtrl.disabled"
                                                    aria-label="Reference2">
                                            </p>
                                        </div>
                                    </div>                                         

                                </fieldset>
                                </div>
                                </div>
                                <div class="modal-footer">                               
                                    <button class="btn btn-primary" type="button"  ng-disabled="!addNewCtrl.GetEnabled()" ng-click="addNewCtrl.add()">{{addNewCtrl.SaveBtnText}}</button>
                                    <button class="btn btn-info" type="button" ng-click="addNewCtrl.cancel()">Cancel</button>
                                </div>
                               
                    `,
                    controller: class addNewCtrl {
                        
                        Allocation: interfaces.applicationcore.IDropdownModel;
                        AllocationList: interfaces.applicationcore.IDropdownModel[] = [];
                        forexSurrender: interfaces.forex.IForexSurrender;
                        ValueDate: moment.Moment;
                        DateDrawn: moment.Moment;                        
                        Amount: number = 0;
                        Type: interfaces.applicationcore.IDropdownModel;
                        Reference1: string;
                        Reference2: string;


                        SaveBtnText : string = "Add"

                        loadPromises: ng.IPromise<any>[];

                        disabled : boolean = false;

                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private refundSettingService: interfaces.master.IRefundSettingService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                            private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                            private supplierService: interfaces.master.ISupplierService,
                            private customerService: interfaces.master.ICustomerService,
                            private $q: ng.IQService,
                            private forexService: interfaces.forex.IForexService,
                            private forexSurrenderUpdate: interfaces.forex.IForexSurrender,
                            private forex: interfaces.forex.IForex,
                            private currencyModel:  interfaces.applicationcore.IDropdownModel,
                        ) {
                            if (this.forexSurrenderUpdate){
                                this.SaveBtnText = "Save";

                                if (this.forexSurrenderUpdate.Type.Id === 615 || this.forexSurrenderUpdate.Type.Id === 617)
                                {
                                    this.disabled = true;
                                }
                            }
                            else
                            {
                                this.DateDrawn = moment.utc();
                            }

                           for (let forexAllocation of this.forex.forexAllocationList) {
                            if (forexAllocation.Id){
                                var Code : string = "";

                                    if(forexAllocation.PurchaseOrder)
                                    {
                                        Code = forexAllocation.Entity.Code + ' - ' + forexAllocation.PurchaseOrder.Display  + ' - ' + forexAllocation.Amount;
                                    }
                                    else if(forexAllocation.SalesOrder)
                                    {
                                        Code = forexAllocation.Entity.Code + ' - ' + forexAllocation.SalesOrder.Display  + ' - ' + forexAllocation.Amount;
                                    }
                                    else
                                    {
                                        Code = forexAllocation.Entity.Code+ ' - ' + forexAllocation.Amount;
                                    }

                                    var  allocation : interfaces.applicationcore.IDropdownModel = <interfaces.applicationcore.IDropdownModel>{
                                        Id: forexAllocation.Id,
                                        Code: Code,
                                        Description: forexAllocation.Entity.Code ,
                                        Display: Code
                                    }
                                    this.AllocationList.push(allocation);

                                }
                            }

                            this.loadControls();
                        }

                        GetEnabled(){
                            return  this.Allocation && this.Amount && this.Type && this.Reference1 && !this.disabled;
                        }

                        loadSurrenderTypes(){
                            return this.classificationValueService.GetClassificationValueDropdownByClassification().query({
                                classificationId: Enum.ClassificationEnum.SurrenderingType
                            }, (resultList) => {
                                   
                            }).$promise;
                        }

                        loadAllocations(){
                            var defered = this.$q.defer();
                            defered.resolve(this.AllocationList);
                            return defered.promise;
                        }

                        loadControls() {
                            this.loadPromises = [];                    
                            this.loadPromises.push(this.loadSurrenderTypes());                   
                            return this.$q.all(this.loadPromises).then((data) => {
                                    //Now we can load the defaults AFTER all the dropdowns have been loaded

                                    if (this.forexSurrenderUpdate)
                                    {
                                        this.Allocation = this.forexSurrenderUpdate.Allocation;
                                        this.ValueDate = this.forexSurrenderUpdate.ValueDate;
                                        this.DateDrawn = this.forexSurrenderUpdate.DateDrawn;
                                        this.Amount = this.forexSurrenderUpdate.Amount;
                                        this.Type = this.forexSurrenderUpdate.Type;
                                        this.Reference1 = this.forexSurrenderUpdate.Reference1;
                                        this.Reference2 = this.forexSurrenderUpdate.Reference2;                                     
                                    }
                            });            
                        }

                  
                        add() { 
                            
                            this.forexSurrender = {   Id:  this.forexSurrenderUpdate?  this.forexSurrenderUpdate.Id:0,
                                                      index: this.forexSurrenderUpdate? this.forexSurrenderUpdate.index:0,
                                                      Allocation : this.Allocation,
                                                      ValueDate: this.ValueDate,
                                                      DateDrawn: this.DateDrawn,
                                                      Amount: this.Amount,
                                                      Type: this.Type,
                                                      Reference1: this.Reference1,
                                                      Reference2: this.Reference2
                                                    }
                            this.$uibModalInstance.close(this.forexSurrender);
                        }

                        cancel() {
                            this.$uibModalInstance.close(null);
                        }
                    },
                    controllerAs: "addNewCtrl",
                    resolve: { 
                        forexSurrenderUpdate :  this.selectedSurrenderEntity? this.selectedSurrenderEntity: null,
                        forex : this.forex,
                        currencyModel: this.forex.Currency,
                    }
                }).result.then((result  : interfaces.forex.IForexSurrender) => {
                   
                    if (result) {
                        if (this.selectedSurrender){

                            this.selectedSurrender.entity = result;  
                            var alloc = this.forex.forexSurrenderList.filter(x => x.index == result.index)[0];
                            
                            alloc.Amount = result.Amount;
                            alloc.Allocation = result.Allocation;
                            alloc.DateDrawn = result.DateDrawn;
                            alloc.ValueDate = result.ValueDate;
                            alloc.Type = result.Type;
                            alloc.Reference1 = result.Reference1;
                            alloc.Reference2 = result.Reference2;

                            if(this.apiSurrenderList){
                                this.apiSurrenderList.rowEdit.setRowsDirty([this.selectedSurrender.entity]);
                            }

                        }
                        else 
                        {
                            result.index = this.forex.forexSurrenderList.length;
                            this.forex.forexSurrenderList.push(result); 

                            if(this.apiSurrenderList){
                                this.apiSurrenderList.rowEdit.setRowsDirty([result]);
                            }
                        }


 
                        this.$timeout(() => {
                           
                        });                      
                    }

                    
                   this.selectedSurrender = null;
                    this.selectedSurrenderEntity = null;
                    this.apiSurrenderList.selection.clearSelectedRows();

                   

                    this.$timeout(() => {
                        if (this.apiSurrenderList) {
                            this.recalculateSurrenderBalances();        
                        }
                    });    

                   

                });
            }

            TransferToCFCAccount(){
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `<div class="modal-header"><h3 class="modal-title" id="modal-title">Transfer to CFC Account</h3></div>
                               <div class="modal-body">
                                <div class="gts-padding">
                                    <fieldset>
                                        <div class="row vertical-align">
                                            <div class="col-md-3">
                                                <req></req>
                                                <label>CFC Account</label>
                                            </div>
                                            <div class="col-md-5">
                                                <gts-dropdown name="CFCAccount" 
                                                    ng-model="addNewCtrl.cfcAccountModel"
                                                    load-data="addNewCtrl.loadCFCAccountList()" pagination-search="true"
                                                    refresh-delay="1000" required="false">
                                                </gts-dropdown>
                                            </div>
                                        </div>
                                        <div class="row vertical-align"  >
                                            <div class="col-md-3">
                                                <req></req>
                                                <label>Transfer Amount</label>
                                            </div>
                                            <div class="col-md-5">
                                            <p class="input-group input-group-sm has-feedback"
                                                ng-class="{'has-error': addNewCtrl.Amount.$invalid}">
                                                <input type="number" ignore-wheel name="Amount"
                                                    ng-model="addNewCtrl.Amount" class="form-control" step="0.01" />
                                                <span class="input-group-addon">{{addNewCtrl.currencyModel.Code}}</span>
                                            </p>                                                            
                                            </div>                                                      
                                        </div>                                         
                                    </fieldset>
                                </div>
                               </div>
                               <div class="modal-footer">                               
                                    <button class="btn btn-primary" type="button"  ng-disabled="!addNewCtrl.GetEnabled()" ng-click="addNewCtrl.transfer()">Transfer</button>
                                    <button class="btn btn-info" type="button" ng-click="addNewCtrl.cancel()">Cancel</button>
                               </div>
                              `,
                              controller: class addNewCtrl {

                                cfcAccountModel: interfaces.applicationcore.IDropdownModel;
                                Amount: number = 0;

                                constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                                    private countryService: interfaces.applicationcore.ICountryService,
                                    private entityService: interfaces.applicationcore.IEntityService,
                                    private refundSettingService: interfaces.master.IRefundSettingService,
                                    public generalService: interfaces.applicationcore.IGeneralService,
                                    private $rootScope: interfaces.applicationcore.IRootScope,
                                    private $state: ng.ui.IStateService,
                                    private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                                    private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                                    private supplierService: interfaces.master.ISupplierService,
                                    private customerService: interfaces.master.ICustomerService,
                                    private $q: ng.IQService,
                                    private forexService: interfaces.forex.IForexService,
                                    private bsLoadingOverlayService,
                                    private fecId : number,
                                    private entityId:  number,
                                    private currencyId: number,
                                    private currencyModel:  interfaces.applicationcore.IDropdownModel,
                                ){}
                                
                                cancel() {
                                    this.$uibModalInstance.close(null);
                                }

                                GetEnabled(){
                                    return  this.cfcAccountModel && this.Amount;
                                }

                                transfer(){
                                    var cfcTransfer : interfaces.forex.ICFCTransfer = {fecContractFromId:this.fecId, cfcContractToId: this.cfcAccountModel.Id, amountToTransfer: this.Amount};
                                    this.$uibModalInstance.close(cfcTransfer);
                                }

                                loadCFCAccountList() {
                                    return this.forexService.getCfCDropdownList().query({
                                        ownerEntityId: this.entityId,
                                        currencyId: this.currencyId
                                    }, () => {
                    
                                    }, (failureData) => {
                                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                    }).$promise;
                                }

                              },
                              controllerAs: "addNewCtrl",
                              resolve: { 
                                fecId :  this.forex.Id,
                                entityId: this.forex.OwnerEntityId,
                                currencyId: this.forex.Currency.Id,
                                currencyModel: this.forex.Currency,
                            }
                    
                }).result.then((result  : interfaces.forex.ICFCTransfer) => {
                        if (result)
                        {
                            this.transfer(result.fecContractFromId,result.cfcContractToId,result.amountToTransfer);
                        }
                });
            }


            transfer(fecId: number, cfcAccountId: number, Amount: number){
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'forex.update'
                },
                    () => {
                        return this.forexService.TransferToCFC(fecId,cfcAccountId,Amount).remove((result) => {
                            this.generalService.displayMessageHandler(result);
                            if (!result.HasErrorMessage){
                                this.loadForex(this.$stateParams.fecId);
           
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            AddAllocation(){
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `<div class="modal-header"><h3 class="modal-title" id="modal-title">Add Allocation</h3></div>
                                <div class="modal-body"> 
                                <div class="gts-padding">
                                <fieldset>
                                    <div class="row vertical-align">
                                        <div class="col-md-3">
                                            <req></req>
                                            <label>Owner Entity</label>
                                        </div>
                                        <div class="col-md-5">
                                            <gts-dropdown name="OwnerEntityId"  ng-change="addNewCtrl.ownerEntityChanged()"
                                                ng-model="addNewCtrl.ownerEntityModel" ng-disabled="addNewCtrl.LockdownType>=2"
                                                load-data="addNewCtrl.loadEntities(searchText)" pagination-search="true"
                                                refresh-delay="1000" required="false">
                                            </gts-dropdown>
                                        </div>
                                    </div>
                                    <div class="row vertical-align" ng-if="addNewCtrl.typeId === 467">
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Customer</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <gts-dropdown  ng-disabled="addNewCtrl.LockdownType>=2" name="customer" ng-model="addNewCtrl.customerModel"   load-data="addNewCtrl.loadCustomers(searchText)" required="true"  pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                                        </div>
                                    </div>
                                    <div class="row vertical-align" ng-if="addNewCtrl.typeId === 467">
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Sales Order</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <gts-dropdown ng-disabled="addNewCtrl.LockdownType>=2" name="salesOrder" ng-model="addNewCtrl.salesOrderModel"   load-data="addNewCtrl.loadSalesOrders(searchText)" required="true"  pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                                        </div>
                                    </div>                                    
                                    <div class="row vertical-align" ng-if="addNewCtrl.typeId === 466">
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Supplier</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <gts-dropdown ng-disabled="addNewCtrl.LockdownType>=2" name="customer" ng-model="addNewCtrl.supplierModel"   load-data="addNewCtrl.loadSuppliers(searchText)" required="true"  pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                                        </div>
                                    </div>    
                                    <div class="row vertical-align" ng-if="addNewCtrl.typeId === 466">
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Purchase Order</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <gts-dropdown ng-disabled="addNewCtrl.LockdownType>=2" name="purchaseOrder" ng-model="addNewCtrl.purchaseOrderModel"   load-data="addNewCtrl.loadPurchaseOrders(searchText)" required="true"  pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                                        </div>
                                    </div>
                                    <div class="row vertical-align"  >
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Amount</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                        <p class="input-group input-group-sm has-feedback"
                                                            ng-class="{'has-error': addNewCtrl.Amount.$invalid}">
                                                            <input ng-disabled="addNewCtrl.LockdownType>=2" type="number" ignore-wheel name="Amount" ng-change="addNewCtrl.AmountChanged()"
                                                                ng-model="addNewCtrl.Amount" class="form-control" step="0.01" />
                                                            <span class="input-group-addon">{{addNewCtrl.currencyModel.Code}}</span>
                                                        </p>                                                            
                                                        </div>
                                                        <div class="col-md-3">
                                                        <p class="input-group input-group-sm has-feedback">
                                                            <input ng-disabled="addNewCtrl.LockdownType>=2" type="number" ignore-wheel name="Percentage" ng-change="addNewCtrl.PercentageChanged()"
                                                                ng-model="addNewCtrl.Percentage" class="form-control" step="0.01" />
                                                            <span class="input-group-addon">%</span>
                                                        </p>                                                            
                                                        </div>                                                        
                                    </div>
                                    <div class="row vertical-align" >
                                                        <div class="col-md-3">
                                                            <req></req>
                                                            <label>Value Surrendered</label>
                                                        </div>
                                                        <div class="col-md-5">
                                                        <p class="input-group input-group-sm has-feedback"
                                                            ng-class="{'has-error': addNewCtrl.Surrendered.$invalid}">
                                                            <input type="number" ignore-wheel name="Surrendered" ng-disabled="true"
                                                                ng-model="addNewCtrl.Surrendered" class="form-control" step="0.01" />
                                                            <span class="input-group-addon">{{addNewCtrl.currencyModel.Code}}</span>
                                                        </p>                                                            
                                                        </div>
                                                        <div class="col-md-3">
                                                            of {{addNewCtrl.ContractAmount | number}}&nbsp;{{addNewCtrl.currencyModel.Code}}                                                     
                                                        </div>                                                          
                                    </div>                                                                                                                                                     
                                </fieldset>
                                </div>
                                </div>
                                <div class="modal-footer">                               
                                    <button class="btn btn-primary" type="button"  ng-disabled="!addNewCtrl.GetEnabled() || addNewCtrl.LockdownType>=2" ng-click="addNewCtrl.add()">{{addNewCtrl.SaveBtnText}}</button>
                                    <button class="btn btn-info" type="button" ng-click="addNewCtrl.cancel()">Cancel</button>
                                </div>
                               
                    `,
                    controller: class addNewCtrl {
                        
                        forexAllocation: interfaces.forex.IForexAllocation;
                        ownerEntityModel: interfaces.applicationcore.IDropdownModel;
                        supplierModel : interfaces.applicationcore.IDropdownModel;
                        customerModel :  interfaces.applicationcore.IDropdownModel;
                        salesOrderModel :  interfaces.applicationcore.IDropdownModel;
                        purchaseOrderModel :  interfaces.applicationcore.IDropdownModel;
                        entityList: interfaces.applicationcore.IDropdownModel[];
                        Amount: number = 0;
                        Surrendered: number = 0;
                        Percentage: number = 0;
                        SaveBtnText : string = "Add"

                        loadPromises: ng.IPromise<any>[];


                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService,
                            private countryService: interfaces.applicationcore.ICountryService,
                            private entityService: interfaces.applicationcore.IEntityService,
                            private refundSettingService: interfaces.master.IRefundSettingService,
                            public generalService: interfaces.applicationcore.IGeneralService,
                            private $rootScope: interfaces.applicationcore.IRootScope,
                            private $state: ng.ui.IStateService,
                            private refundClaimTypeService: interfaces.tariff.IRefundClaimTypeService,
                            private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                            private supplierService: interfaces.master.ISupplierService,
                            private customerService: interfaces.master.ICustomerService,
                            private $q: ng.IQService,
                            private forexService: interfaces.forex.IForexService,
                            private forexAllocationUpdate: interfaces.forex.IForexAllocation,
                            private typeId : number,
                            private currencyModel:  interfaces.applicationcore.IDropdownModel,
                            private entityId:  number,
                            private ContractAmount: number,
                            private LockdownType : number,
                        ) {
                            if (this.forexAllocationUpdate){
                                this.SaveBtnText = "Save";
                            }
                            this.loadControls();
                        }

                        GetEnabled(){
                            if (this.typeId === 466) //Purchase
                                return this.ownerEntityModel && this.supplierModel && this.purchaseOrderModel && this.Amount;
                            else
                             return this.ownerEntityModel && this.customerModel && this.salesOrderModel && this.Amount;
                        }

                        loadEntities(searchText: string) {
                            return this.entityService.getDropdownList(searchText).query(
                                (data:  interfaces.applicationcore.IDropdownModel[]) => {
                                    this.entityList = data;
                                }, (failureData) => {
                                }).$promise;
                        }

                        ownerEntityChanged(){
                            this.supplierModel = null;
                            this.customerModel = null;
                            this.salesOrderModel = null;
                            this.purchaseOrderModel  = null;
                        }

                        AmountChanged(){
                            this.Percentage = (this.Amount / this.ContractAmount) * 100
                        }

                        PercentageChanged(){
                            if (this.Percentage)
                            {
                                this.Amount = (this.Percentage/100) * this.ContractAmount;
                            }
                        }

                        loadControls() {
                            this.loadPromises = [];                    
                            this.loadPromises.push(this.loadEntities(''));                   
                            return this.$q.all(this.loadPromises).then((data) => {
                                    //Now we can load the defaults AFTER all the dropdowns have been loaded
                                    this.ownerEntityModel =  this.entityList.filter(x => x.Id == this.entityId)[0];

                                    if (this.forexAllocationUpdate)
                                    {
                                        this.ownerEntityModel = this.forexAllocationUpdate.Entity;

                                        if (this.typeId === 466)
                                        {
                                            this.supplierModel = this.forexAllocationUpdate.Company;
                                            this.purchaseOrderModel = this.forexAllocationUpdate.PurchaseOrder;
                                        }
                                        else
                                        {
                                            this.customerModel = this.forexAllocationUpdate.Company;
                                            this.salesOrderModel = this.forexAllocationUpdate.SalesOrder;
                                        }

                                        this.Amount = this.forexAllocationUpdate.Amount; 
                                        this.AmountChanged();                                       
                                    }
                            });            
                        }

                        loadPurchaseOrders(searchText: string) {
                            return this.forexService.getPODropdownList().query({
                                ownerEntityId: this.ownerEntityModel.Id,
                                supplierId: this.supplierModel.Id,
                                currencyId: this.currencyModel.Id,
                                searchText: searchText
                            }, () => {
            
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                        }

                        loadSalesOrders(searchText: string) {
                            return this.forexService.getSODropdownList().query({
                                ownerEntityId: this.ownerEntityModel.Id,
                                customerId: this.customerModel.Id,
                                currencyId: this.currencyModel.Id,
                                searchText: searchText
                            }, () => {
            
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                            }).$promise;
                        }                        

                        //Load Suppliers
                        loadSuppliers(searchText: string)
                        {
                            if (this.ownerEntityModel) {
                                return this.supplierService.getSupplierDropdown(this.ownerEntityModel.Id, searchText).query(() => {
            
                                }, (failureData) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                }).$promise;
                            } else {
                                var defer = this.$q.defer();
                                defer.resolve([]);
                                return defer.promise;
                            }
                        }
            
                        //Load Customers
                        loadCustomers(searchText: string) {
                            if (this.ownerEntityModel) {
                                return this.customerService.getDropdownList(this.ownerEntityModel.Id, searchText).query(() => {
            
                                }, (failureData) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                                }).$promise;
                            } else {
                                var defer = this.$q.defer();
                                defer.resolve([]);
                                return defer.promise;
                            }
                        }
            

                        add() { 
                            
                            if (this.purchaseOrderModel && !this.forexAllocationUpdate) {
                                this.purchaseOrderModel.Display = this.purchaseOrderModel.Code;                            
                            }
                            if (this.salesOrderModel  && !this.forexAllocationUpdate){
                                this.salesOrderModel.Display = this.salesOrderModel.Code;
                            }

                            this.forexAllocation = {   Id:  this.forexAllocationUpdate?  this.forexAllocationUpdate.Id:0,
                                                        index: this.forexAllocationUpdate? this.forexAllocationUpdate.index:0,
                                                        Entity: this.ownerEntityModel, 
                                                        Company: this.customerModel?this.customerModel : this.supplierModel,
                                                        SalesOrder: this.salesOrderModel,
                                                        PurchaseOrder: this.purchaseOrderModel,
                                                        Amount: this.Amount,
                                                        Balance: this.Amount,
                                                        SurrenderedAmount: 0,
                                                        Currency: this.currencyModel
                                                    }
                            this.$uibModalInstance.close(this.forexAllocation);
                        }

                        cancel() {
                            this.$uibModalInstance.close(null);
                        }
                    },
                    controllerAs: "addNewCtrl",
                    resolve: { 
                        forexAllocationUpdate :  this.selectedAllocationEntity? this.selectedAllocationEntity: null,
                        typeId: this.forex.typeId,
                        currencyModel: this.forex.Currency,
                        entityId:    this.forex.OwnerEntityId,
                        ContractAmount: this.forex.ContractAmount,
                        LockdownType: this.forex.LockdownType
                    }
                }).result.then((result  : interfaces.forex.IForexAllocation) => {
                   
                    if (result) {
                        if (this.selectedAllocation){
      
                            this.selectedAllocation.entity = result;  
                            var alloc = this.forex.forexAllocationList.filter(x => x.index == result.index)[0];
 
                            alloc.Amount = result.Amount;
                            alloc.Balance = result.Balance;
                            alloc.Entity = result.Entity;
                            alloc.Company = result.Company;
                            alloc.SalesOrder = result.SalesOrder;
                            alloc.PurchaseOrder = result.PurchaseOrder;
                            
                            if(this.apiAllocationList){
                                this.apiAllocationList.rowEdit.setRowsDirty([this.selectedAllocation.entity]);
                            }

                        }
                        else 
                        {
                            result.index = this.forex.forexAllocationList.length;
                            this.forex.forexAllocationList.push(result); 

                            if(this.apiAllocationList){
                                this.apiAllocationList.rowEdit.setRowsDirty([result]);
                            }

                        }


 
                        this.$timeout(() => {
                           
                        });                      
                    }

                    
                   this.selectedAllocation = null;
                    this.selectedAllocationEntity = null;
                    this.apiAllocationList.selection.clearSelectedRows();

                   

                    this.$timeout(() => {
                        if (this.apiAllocationList) {
                            this.recalculateBalances();        
                        }
                    });    

                   

                });
            }


        }//end class


        angular.module("app").controller("forexUpdateCtrl", controllers.forex.forexUpdateCtrl);
    } //end module
} //end controllers